import { FillableBy } from "./form_enums";
import { getSignatures } from "../commonDynmicForms/signature";
import { table_fields} from "../commonDynmicForms/table_fields"
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { constants } from "../constants";

export default {
    fillable_by : FillableBy.Both,
    title:
        "Cardiohelp Competency Checklist",
    sections: [
        {
            title: "Identify the following components of the circuit",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Power button",
                "Emergency override button",
                "Safety button",
                "Unlock button",
                "<b>Connections for external pressure sensors<br/></b>1. Venous pressure <br/>2. Internal pressure <br/> 3. Arterial pressure",
                "Connection for sensors of disposable HLS set",
                "Connection for flow/bubble sensor",
                "Connection for venous probe sensor",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Demonstrate the following skills",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Turn the Cardiohelp console on",
                "Zero the flow probe",
                "Activate Global Override mode",
                "Identify when console is in Global Override mode",
                "Deactivate Global Override mode",
                "Zero the pressure sensors (explain at what point should pressures be zeroed)",
                "Set RPMs to 3000 using the knob",
                "Set RPMs to 3300 using touchscreen",
                "Lock the screen",
                "Unlock the screen",
                "Set the flow low alarm limit to 1.5 LPM",
                "Start data recording at 5 minute interval",
                "Demonstrate how to verify system is recording data on the home screen",
                "Activate timer 1",
                "Set countdown timer to 15 minutes and activate it",
                "Set the intervention of the arterial pressure limit to 140mmHg",
                "Increate RPMs to 1500, slowly clamp arterial line, and explain what is happening",
                "Identify how one can determine an intervention is set and active, then turn off the intervention for the arterial pressure limit",
                "Identify the current pressure drop and set an alarm for double the current value",
                "Set the flow to 2.0 LPM in LPM mode, then slowly clamp the arterial line and explain what happens",
                "Confirm the arterial bubble intervention is not activated",
                "Open the arterial bubble sensor and explain what happens",
                "Activate arterial bubble monitoring intervention",
                "Explain why the arterial bubble alarm may be activated and what would happen to the console/circuit if it was activated while an intervention was set",
                "Open the arterial bubble sensor",
                "Reattach arterial bubble sensor and reset the bubble alarm",
                "Explain zero flow mode",
                "Activate zero flow mode",
                "Why may zero flow mode occur? Is zero flow mode the same as zero RPMs?",
                "Explain possible reason and trouble shooting for “Pump Disposable Error”",
                "Deactivate zero flow mode",
                "State the battery life of Cardiohelp and where and when it should be plugged in",
                "Place the venous probe on the blood phantom",
                "<b>Recalibrate the blood parameters</b><br/>1.Save the current parameters<br/>2. Update the blood draw with the following labs:  SVO2 86, Hgb 11.0, HCT 26",
                "End data recording and explain how to export the data to a USB stick",
                "<b>Activate Emergency Mode</b><br/>1. Describe how to turn off emergency mode<br/>2. Describe what features/aspects of Cardiohelp fully function in emergency mode",
                "Demonstrate how to determine current battery life",
                "Demonstrate how to determine if console is connected to AC power/charging/running on battery",
                "Demonstrate how to raise and lower the safety bar",
                "Hand crank at 4000 rpms",
                "Demonstrate how to return HLS disposable from the hand crank to a console",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both),
        },
    ],
}